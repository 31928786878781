import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '../modal.component';
import { Subject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import {
  IN_PERSON_MODE_OF_HELP,
  ONLINE_MODE_OF_HELP,
  PHYSICIAN_MODE_OF_HELP
} from '../../../../../@core/utils/constants';

@Component({
  selector: 'ngx-switch-to-online-modal',
  templateUrl: './switch-to-online-modal.component.html',
  styleUrls: ['./switch-to-online-modal.component.scss'],
})
export class SwitchToOnlineModalComponent implements OnInit {
  @Input() modeOfHelp: string;
  @Input() useCase: string;
  heading: string = 'How BetterHelp matches you with a Counselor';
  counselingFor: string = '';
  whySwitch: string = '';

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  value: any;
  reasons: any;
  switchToBHModesOfHelp = [PHYSICIAN_MODE_OF_HELP, IN_PERSON_MODE_OF_HELP];

  constructor(
    private readonly activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.reasons = environment.reasons;
  }

  closeModal() {
    this.activeModal.close(ConfirmationModal.No);
  }

  accept(): void {
    this.activeModal.close({
      answer: ConfirmationModal.Yes,
      selectState: [],
      counselingFor: this.counselingFor,
      test: ONLINE_MODE_OF_HELP,
      reasonForSwitch: this.whySwitch,
    });
  }
  completeRequest() {
    this.counselingFor = 'Individual';
    if (this.useCase === 'faceted') {
      this.whySwitch = 'Wanted a different counseling mode';
    }
    this.accept();
  }

  enableSwitchToBHCounselorBtn() {
    return this.switchToBHModesOfHelp.includes(this.modeOfHelp);
  }

}
