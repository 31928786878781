<div class="modal-content" style="margin-top: 0px">
  <span class="close-icon" (click)="closeModal()">&#10005;</span>
  <div class="text-center message-container">
    <div
      class="row justify-content-center align-content-center align-items-center no-gutters mb-5">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <div
          class="counselor-text justify-content-center align-content-center align-items-center">
          <p class="modal-heading">
            You are switching to BetterHelp Counseling
          </p>
          <p>
            <img
              class="concern-logo"
              src="../../../../../../assets/images/logo/concern-one-color-logo.png"
              alt="Concern Logo" />
            <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
            <img
              class="bh-logo"
              src="../../../../../../assets/icons/bh_logo_small.png"
              alt="BetterHelp Logo" />
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12">
      <p class="about-bh">
        We have partnered with <span>BetterHelp</span> to give you more
        counseling choices, including:
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center">
      <ul class="bh-steps left-side">
        <li class="steps">Video</li>
        <br />
        <li class="steps">Phone</li>
      </ul>
      <ul class="bh-steps right-side">
        <li class="steps">Text</li>
        <br />
        <li class="steps">Chat</li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12">
      <div class="d-flex justify-content-center">
        <p class="desc-text">
          BetterHelp also has a large network of counselors, accessible anywhere
          you have an internet connection.
          <a href="https://www.betterhelp.com/faq/">Learn more about BetterHelp.</a>
        </p>
      </div>
    </div>
  </div>
  <!-- Options -->
  <div class="stateModel">
    <div class="text-center message-container">
      <div *ngIf="useCase !== 'faceted'">
      <div class="justify-content-center align-content-center align-items-center">
        <p class="switch-reason">Why would you like to switch counselors?</p>
      </div>
      <div class="col-12 col-md-7 mx-auto form-group">
        <div class="dropdown">
          <select
            name="why-switch"
            class="form-control within"
            [(ngModel)]="whySwitch">
            <option *ngFor="let reason of reasons" [value]="reason.id">
              {{ reason.value }}
            </option>
          </select>
        </div>
      </div>
      </div>
    </div>

    <div
    class="row text-center justify-content-center align-content-center align-items-center no-gutters">
    <div class="col-xs-12 col-md-12 col-xl-12 faceted-accept">
      <button
      *ngIf="useCase === 'faceted'"
      class="btn btn-primary close-btn form-control"
      (click)="completeRequest()">
      CONTINUE
    </button>
    </div>
  </div>

    <div class="row">
      <div
        class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
        <button *ngIf="useCase==='faceted'" class="btn btn-primary btn-condition faceted-button" (click)="closeModal()">
          CANCEL
        </button>
      </div>
    </div>

    <div class="second-line">
      <div class="row second-left-side">
        <div class="col-xs-12 col-md-12 col-xl-12">
          <p class="section-heading bh-section-heading">BETTERHELP SOLUTIONS</p>
          <div class="logos">
            <div class="child1 logo-parent">
              <div class="d-flex individuals justify-content-center">
                <p class="paratitle">For Individuals:</p>
                <img
                  class="bh-help-logo"
                  src="../../../../../../assets/icons/logo-betterhelp-small.png"
                  alt="BetterHelp logo" />
              </div>
              <div class="d-flex couples justify-content-center">
                <p>For Couples:</p>
                <img
                  class="regain-help-logo"
                  src="../../../../../../assets/icons/logo-Regain.png"
                  alt="Regain Logo" />
              </div>
              <div class="d-flex teens justify-content-center">
                <p>For Teens:</p>
                <img
                  class="teens-help-logo"
                  src="../../../../../../assets/icons/logo-teamcounseling.png"
                  alt="Teen Counseling Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- State Option -->
  <div
    class="row text-center justify-content-center align-content-center align-items-center no-gutters">
    <div *ngIf="useCase !== 'faceted'" class="col-xs-12 col-md-12 col-xl-12">
      <p *ngIf="modeOfHelp === 'online'" class="yes-text">
        Do you still wish to switch to another BetterHelp counselor?
      </p>
      <p *ngIf="modeOfHelp === 'in-person'" class="yes-text">
        Do you still wish to switch to BetterHelp counseling?
      </p>
    </div>
  </div>

  <div
    class="row text-center justify-content-center align-content-center align-items-center no-gutters">
    <div class="col-xs-12 col-md-12 col-xl-12">
      <button *ngIf="useCase!=='faceted'" class="btn btn-primary close-btn" (click)="closeModal()">
        NO, DON'T CHANGE ANYTHING
      </button>
    </div>
  </div>

  <div class="row">
    <div
      class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <button
        *ngIf="enableSwitchToBHCounselorBtn() && useCase!=='faceted'"
        class="btn btn-primary btn-condition"
        (click)="completeRequest()"
        [ngClass]="{ disabled: whySwitch === '' }">
        YES, LET’S SWITCH TO A BETTERHELP COUNSELOR
      </button>
      <button
        *ngIf="modeOfHelp === 'online' && useCase!=='faceted'"
        class="btn btn-primary btn-condition"
        (click)="completeRequest()"
        [ngClass]="{ disabled: whySwitch === '' }">
        YES, SWITCH TO ANOTHER BETTERHELP COUNSELOR
      </button>
    </div>
  </div>
</div>
